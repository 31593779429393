@font-face {
	font-family: 'Bebas Neue';
	src: url('BebasNeue-Thin.ttf');
	src: local('BebasNeue Thin'), local('BebasNeue-Thin'), url('BebasNeue-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('BebasNeue-Light.ttf');
	src: local('BebasNeue Light'), local('BebasNeue-Light'), url('BebasNeue-Light.ttf') format('truetype');
	font-weight: 200;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('BebasNeue-Book.ttf');
	src: local('BebasNeue Book'), local('BebasNeue-Book'), url('BebasNeue-Book.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('BebasNeue-Regular.ttf');
	src: local('BebasNeue Regular'), local('BebasNeue-Regular'), url('BebasNeue-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: 'Bebas Neue';
	src: url('BebasNeue-Bold.ttf');
	src: local('BebasNeue Bold'), local('BebasNeue-Bold'), url('BebasNeue-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
}
