@font-face {
  font-family: 'Aventa';
  src: url('Aventa-Thin.ttf');
  src: local('Aventa Thin'), local('Aventa-Thin'), url('Aventa-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Aventa Extra';
  src: url('Aventa-ExtraLight.ttf');
  src: local('Aventa Extra Light'), local('Aventa-ExtraLight'), url('Aventa-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}

@font-face {
  font-family: 'Aventa';
  src: url('Aventa-Light.ttf');
  src: local('Aventa Light'), local('Aventa-Light'), url('Aventa-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Aventa';
  src: url('Aventa-Regular.ttf');
  src: local('Aventa'), local('Aventa-Regular'), url('Aventa-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Aventa';
  src: url('Aventa-Medium.ttf');
  src: local('Aventa Medium'), local('Aventa-Medium'), url('Aventa-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Aventa Semi';
  src: url('Aventa-SemiBold.ttf');
  src: local('Aventa Semi Bold'), local('Aventa-SemiBold'), url('Aventa-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Aventa';
  src: url('Aventa-Bold.ttf');
  src: local('Aventa Bold'), local('Aventa-Bold'), url('Aventa-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Aventa Extra';
  src: url('Aventa-ExtraBold.ttf');
  src: local('Aventa Extra Bold'), local('Aventa-ExtraBold'), url('Aventa-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Aventa';
  src: url('Aventa-Black.ttf');
  src: local('Aventa Black'), local('Aventa-Black'), url('Aventa-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}
