.canvas
  position: fixed
  left: 50%
  top: 50%
  width: 100%
  height: calc(100 * var(--vh))
  transform: translate(-50%, -50%)
  display: flex
  align-items: center
  justify-content: center
  overflow: hidden
  animation: fadeIn 1000ms cubic-bezier(.3,.1,.2,1) forwards
  opacity: 0
  z-index: 1
  @media #{$tablet}
    aspect-ratio: 16/9
    width: auto
  // &::before
  //   content: ''
  //   position: absolute
  //   left: 0
  //   top: 0
  //   z-index: 2
  //   width: 100%
  //   height: 100%
  //   background-color: #000000
  //   mix-blend-mode: difference
