@import './animation'
@import './vars'
@import './mixin'

*, *:before, *:after
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-decoration: none
  font-family: Aventa, SF Pro Display
  font-weight: 400
  line-height: 120%
  color: $white
  outline: none
  padding: 0
  margin: 0
  box-sizing: border-box
  -webkit-box-sizing: border-box
  cursor: none !important

html, body
  background-color: $black
  overflow: hidden
  &::-webkit-scrollbar
    display: none

.application
  --aspect-ratio: 16/9
  --vh: 1
  position: relative
  width: 100%
  height: calc(100 * var(--vh))
  overflow: auto
  font-size: 20px
  z-index: 10

@import './pages/home'

@import './components/cursor'
@import './components/canvas'
