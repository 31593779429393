@keyframes fadeIn
  0%
    opacity: 0
  100%
    opacity: 1

@keyframes fadeInUp
  0%
    transform: translateY(20px)
    opacity: 0
  100%
    transform: translateY(0)
    opacity: 1

@keyframes blurIn
  0%
    filter: blur(10px)
    opacity: 0
  100%
    filter: blur(0px)
    opacity: 1